import { notification } from 'antd'
import { plainToClass, serialize } from 'class-transformer'
import moment from 'moment'
import { oc } from 'ts-optchain'
import { IPaginatedResponse } from '../../interfaces/IPaginatedResponse'
import { PaginatedResult } from '../../interfaces/PaginatedResult'
import { Pagination } from '../../interfaces/Pagination'
import { Order, TipoCategoriaCultivar } from '../../models/Order/Order'
import { OrderSimulation } from '../../models/OrderSimulation/OrderSimulation'
import {
  OrderSimulationInstallment,
  SimulationInstallmentType
} from '../../models/OrderSimulation/OrderSimulationInstallment'
import { OrderItem } from '../../models/OrderSimulation/OrderSimulationItem'
import { createApiError } from '../createApiError'
import { createApiErrorReview } from '../createApiErrorReview'
import { serverClient } from '../serverClient'
import { IOrderInfo } from '../../interfaces/IOrderInfo'
import { OrderSimulationBilling } from '../../models/OrderSimulation/OrderSimulationBilling'
import { OrderSimulationDeadlines } from '../../models/OrderSimulation/OrderSimulationDeadlines'
import { AxiosError } from 'axios'

export async function fetchOrders(
  options: {
    filters?: OrderFilters
    requestIncludes?: boolean
    pagination?: Pagination
  } = {}
): Promise<PaginatedResult<Order>> {
  try {
    const include = options.requestIncludes
      ? [
          'Fazenda',
          'Fazenda.Cliente',
          'Fazenda.Cidade',
          'Fazenda.Cidade.Estado',
          'Vendedor',
          'Frete',
          'Pagamento',
          'Pagamento.Parcelas',
          'Itens',
          'Itens.Cultivar',
          'Itens.Tratamento',
          'Itens.Cultivar.Tecnologia',
          'Safra',
          'Envelopes',
          'HistoricoStatus.ProfissionalRealizador',
          'ContratoInfos.ProfissionalAvaliador',
          'ContratoInfos.ProfissionalCancelador'
        ]
      : []

    const filters = {
      Status: oc(options).filters.status(),
      ClienteId: oc(options).filters.client(),
      VendedorId: oc(options).filters.professional(),
      DataInicial: oc(options).filters.initialDate(),
      DataFinal: oc(options).filters.finalDate(),
      CodigoIdentificacao: oc(options).filters.identificationCode(),
      SafraId: oc(options).filters.harvestId(),
      EsconderEstagioSimulacao: oc(options).filters.hideSimulationPhase(),
      EsconderEstagioProposta: oc(options).filters.hideProposalPhase(),
      EsconderEstagioPedido: oc(options).filters.hideOrderPhase(),
      EsconderComStatusTerminal: oc(options).filters.hideTerminal(),
      EsconderCancelado: oc(options).filters.hideCancelled(),
      EsconderVencido: oc(options).filters.hideExpired(),
      TipoVenda: oc(options).filters.salesType(),
      StatusLiberacaoTratamento: oc(options).filters.statusTSI(),
      OrderBy: 'Id_Descend'
    }

    const pagination = {
      Page: oc(options).pagination.current(),
      PageSize: oc(options).pagination.pageSize()
    }

    const { data } = await serverClient.get<IPaginatedResponse<any>>(
      '/Pedido',
      {
        params: {
          include,
          ...filters,
          ...pagination
        }
      }
    )

    return {
      items: plainToClass(Order, data.result),
      pagination: {
        current: data.page,
        pageSize: data.pageItemCount,
        total: data.totalItemCount
      }
    }
  } catch (e) {
    throw createApiError(e)
  }
}

export async function fetchOrder(id: number) {
  try {
    const include = [
      'Fazenda',
      'Fazenda.Cliente',
      'Fazenda.Cliente.Uploads',
      'Fazenda.Cidade',
      'Fazenda.Cidade.Estado',
      'Vendedor',
      'Frete',
      'Pagamento',
      'Pagamento.Parcelas',
      'Itens',
      'Itens.Cultivar',
      'Itens.Tratamento',
      'Itens.Cultivar.Tecnologia',
      'Safra',
      'Envelopes',
      'HistoricoStatus.ProfissionalRealizador',
      'ContratoInfos.ProfissionalAvaliador',
      'ContratoInfos.ProfissionalCancelador'
    ]

    const response = await serverClient.get(`/Pedido/${id}`, {
      params: { include }
    })

    return plainToClass(Order, response.data)
  } catch (e) {
    throw createApiError(e)
  }
}

export async function saveOrder(order: OrderSimulation) {
  try {
    const payload = toPedidoPayload(order)
    const response = await serverClient.post('/Pedido/simulacao', payload)

    return plainToClass(Order, response.data)
  } catch (e) {
    throw createApiError(e)
  }
}

export async function submitOrder(orderId: number) {
  try {
    const response = await serverClient.put(
      `/Pedido/simulacao/submeter/${orderId}`
    )
    return response
  } catch (e) {
    throw createApiError(e as AxiosError)
  }
}

export async function updateOrder(order: OrderSimulation, orderId: number) {
  try {
    const payload = toPedidoPayload(order)
    const response = await serverClient.put(
      `/Pedido/simulacao/${orderId}/atualizar`,
      payload
    )

    return plainToClass(Order, response.data)
  } catch (e) {
    throw createApiError(e as AxiosError)
  }
}

export async function reviewOrder(
  orderId: number,
  reprove: boolean,
  justification: string,
  credit?: boolean,
  salesType?: boolean
) {
  try {
    const data = {
      observacao: justification
    }

    let endpoint = salesType
      ? `/Pedido/simulacao/${orderId}/aprovarTipoVenda`
      : `/Pedido/simulacao/${orderId}/aprovarPreco`

    if (credit) endpoint = `/Pedido/pedido/${orderId}/aprovarCredito`
    if (reprove && credit) endpoint = `/Pedido/pedido/${orderId}/negarCredito`
    if (reprove && !credit) endpoint = `/Pedido/simulacao/${orderId}/negar`

    const response = await serverClient.put(endpoint, data)

    return response
  } catch (e) {
    throw createApiErrorReview(e)
  }
}

export async function fetchOrderSimulationPdf(
  orderId: number,
  orderReadableId: string,
  tipoVolume: string,
  withoutRoyalties?: boolean
) {
  try {
    const params = {
      TipoVolume: tipoVolume,
      ValoresSemTecnologia: withoutRoyalties
    }

    const response = await serverClient.get(
      `/Pedido/simulacao/${orderId}/gerarPdf`,
      { params, responseType: 'blob' }
    )

    const bag = '-em' + tipoVolume
    const royalties = withoutRoyalties ? '-semRoyalties' : ''

    const blob = new Blob([response.data])
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `#${orderReadableId}${bag}${royalties}-simulacao.pdf`
    link.click()
  } catch (e) {
    throw createApiError(e)
  }
}

export async function fetchOrderProposalPdf(
  orderId: number,
  orderReadableId: string,
  tipoVolume: string,
  withoutRoyalties?: boolean
) {
  try {
    const params = {
      TipoVolume: tipoVolume,
      ValoresSemTecnologia: withoutRoyalties
    }

    const response = await serverClient.get(
      `/Pedido/proposta/${orderId}/gerarPdf`,
      { params, responseType: 'blob' }
    )

    const bag = '-em' + tipoVolume
    const royalties = withoutRoyalties ? '-semRoyalties' : ''

    const blob = new Blob([response.data])
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `#${orderReadableId}${bag}${royalties}-proposta.pdf`
    link.click()
  } catch (e) {
    throw createApiError(e)
  }
}

export async function fetchOrderPdf(
  orderId: number,
  orderReadableId: string,
  tipoVolume: string
) {
  try {
    const params = {
      TipoVolume: tipoVolume
    }

    const response = await serverClient.get(
      `/Pedido/pedido/${orderId}/gerarPdf`,
      { params, responseType: 'blob' }
    )

    const bag = '-em' + tipoVolume

    const blob = new Blob([response.data])
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `#${orderReadableId}${bag}-pedido.pdf`
    link.click()
  } catch (e) {
    throw createApiError(e)
  }
}

export async function fetchReleaseTreatment(orderId: number) {
  try {
    const response = await serverClient.put(
      `pedido/${orderId}/liberarTratamento`
    )
    return response
  } catch (e) {
    throw createApiError(e)
  }
}

export async function fetchOrderContractPdf(
  orderId: number,
  orderReadableId: string
) {
  try {
    const response = await serverClient.get(
      `/Pedido/contrato/${orderId}/gerarPdf`,
      { responseType: 'blob' }
    )

    const blob = new Blob([response.data])
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `#${orderReadableId}-contrato.pdf`
    link.click()
  } catch (e) {
    throw createApiError(e)
  }
}

export async function generateContract(
  orderId: number,
  germinacaoMinima: string
) {
  try {
    const payload = { germinacaoMinima }
    const response = await serverClient.put(
      `/Pedido/pedido/${orderId}/gerarContrato`,
      payload
    )
    return response
  } catch (e) {
    throw createApiError(e)
  }
}

export async function generateAditivo(orderId: number) {
  try {
    const response = await serverClient.put(
      `/Pedido/pedido/${orderId}/gerarAditivo`
    )
    return response
  } catch (e) {
    throw createApiError(e)
  }
}

export async function fetchOrderAditivoPdf(
  orderId: number,
  orderReadableId: string
) {
  try {
    const response = await serverClient.get(
      `/Pedido/aditivo/${orderId}/gerarPdf`,
      { responseType: 'blob' }
    )

    const blob = new Blob([response.data])
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `#${orderReadableId}-aditivo.pdf`
    link.click()
  } catch (e) {
    throw createApiError(e)
  }
}

export async function reviewContract(
  orderId: number,
  reprove: boolean,
  justification: string
) {
  try {
    const data = {
      aprovado: !reprove,
      observacao: justification
    }

    let endpoint = `/Pedido/contrato/${orderId}/aprovacao`

    const response = await serverClient.put(endpoint, data)

    return response
  } catch (e) {
    throw createApiError(e)
  }
}

export async function cancelContract(orderId: number, motivo: string) {
  try {
    const data = {
      motivo
    }

    let endpoint = `/Pedido/contrato/${orderId}/cancelar`

    const response = await serverClient.put(endpoint, data)

    return response
  } catch (e) {
    throw createApiError(e)
  }
}

export async function updateSimulationToProposal(
  orderId: number,
  sendEmail: boolean,
  email?: string,
  message?: string
) {
  try {
    const data = {
      enviarEmail: sendEmail,
      emailCliente: email,
      mensagem: message
    }

    const response = await serverClient.put(
      `/Pedido/simulacao/${orderId}/proposta`,
      data
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function refreshOrderRoyaltyPrice(orderId: number) {
  try {
    const response = await serverClient.put(
      `/Pedido/${orderId}/atualizarPrecoTecnologia`
    )

    return response
  } catch (e) {
    notification.error({
      message: 'Preço do Royalty não cadastrado',
      description: `
        Ainda não foram cadastrados os preços para as tecnologias do pedido.
        Solicite ou cadastre o preço para continuar.`,
      placement: 'bottomLeft',
      duration: 0
    })
    throw createApiError(e)
  }
}

export async function fetchOrderStatusInfo(orderId: number) {
  try {
    const headers = {
      'Accept-Language': 'pt-BR'
    }

    const response = await serverClient.get<IOrderInfo>(
      `/Pedido/info/${orderId}`,
      {
        headers
      }
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function reviewOrderProposal(
  orderId: number,
  reprove?: boolean,
  justification?: string
) {
  try {
    const data = reprove ? { motivo: justification } : undefined

    const endpoint = reprove
      ? `/Pedido/proposta/${orderId}/recusar`
      : `/Pedido/proposta/${orderId}/pedido`

    const response = await serverClient.put(endpoint, data)

    return response
  } catch (e) {
    throw createApiError(e)
  }
}

export async function extendOrderDeadline(orderId: number, days: number) {
  try {
    const data = {
      quantidadeDias: days
    }

    const response = await serverClient.put(
      `/Pedido/proposta/${orderId}/estenderValidade`,
      data
    )

    return response
  } catch (e) {
    throw createApiError(e)
  }
}

export async function extendOrderInProgressDeadline(
  orderId: number,
  days: number
) {
  try {
    const data = {
      quantidadeDias: days
    }

    const response = await serverClient.put(
      `/Pedido/pedido/${orderId}/estenderValidade`,
      data
    )

    return response
  } catch (e) {
    throw createApiError(e)
  }
}

export async function cancelEntireOrder(
  orderId: number,
  justification: string
) {
  try {
    const data = { motivo: justification }

    const endpoint = `/Pedido/${orderId}/cancelar`

    const response = await serverClient.put(endpoint, data)

    return response
  } catch (e) {
    throw createApiError(e)
  }
}

export async function finishOrder(orderId: number) {
  try {
    const endpoint = `/Pedido/pedido/${orderId}/finalizar`

    const response = await serverClient.put(endpoint, {})

    return response
  } catch (e) {
    throw createApiError(e)
  }
}

export async function fetchOrderRecriavel(codPedido: string) {
  try {
    const include = [
      'Safra',
      'Vendedor',
      'Fazenda',
      'Fazenda.Cidade',
      'Fazenda.Cliente',
      'Fazenda.Cliente.Uploads',
      'Fazenda.Cliente.Cidade.Estado',
      'Fazenda.Cliente.Cidade.Estado',
      'Fazenda.Cliente.ModeloAcesso',
      'Fazenda.Cliente.TipoCliente',
      'Fazenda.Cliente.DescontoTratamentos',
      'Fazenda.Cliente.DescontoTratamentos.TipoDescontoTratamento'
    ]

    const response = await serverClient.get<Order>(
      `/pedido/${codPedido}/recriavel`,
      { params: { include } }
    )

    return plainToClass(Order, response.data)
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ============================================================================
 * Interfaces
 * ============================================================================
 */

interface ParcelaPedido {
  tipoProdutoParcela: string
  dataVencimento: string
  valor: number
}

interface ItemPedido {
  cultivarId: string
  valorGermoplasmaSacaNegociado: number
  tratamentoId?: string
  quantidadeSaca: number
  AreaEscolhida: number
  DataEmbarque?: string
  ObservacoesEntrega?: string
  PopulacaoEscolhida: number
  IRPercentualEscolhida: number
}

interface PagamentoPedido {
  prazoGermoplasma: string
  prazoTecnologia?: string
  prazoTratamento?: string
  parcelas: ParcelaPedido[]
}

export interface PedidoPayload {
  safraId: string
  vendedorId: number
  fazendaId: number
  usarCnpjFazenda: boolean
  tipoCategoriaCultivar: number
  frete?: {
    valorSacaNegociado: number
  }
  pagamento: PagamentoPedido
  itens: ItemPedido[]
  observacao: string
  pedidoReferenciaId?: number
  solicitacaoOrcamentoId?: string
  frontExtra: string
  tipoVenda?: string
  ValorSaca?: number
  IsFreteCompradorFob?: boolean
  StatusLiberacaoTratamento?: string | number
}

export interface OrderFilters {
  status?: string
  client?: number
  professional?: number
  initialDate?: string
  finalDate?: string
  harvestId?: string
  identificationCode?: string
  hideSimulationPhase?: boolean
  hideProposalPhase?: boolean
  hideOrderPhase?: boolean
  hideTerminal?: boolean
  hideCancelled?: boolean
  hideExpired?: boolean
  salesType?: string
  statusTSI?: string | number
}

interface ApiDeadlines {
  seedDeadline: string
  royaltiesDeadline: string
  treatmentDeadline: string
}

interface ApiInstallment {
  type: string
  expiresAt: string
  value: number
}

interface ApiItem {
  embarkationDate: any
  totalSacks: number
  choosenArea: number
  observacoesEmbarque: string
  choosenPopulation: number
  choosenRate: number
}

/**
 * ============================================================================
 * Helpers
 * ============================================================================
 */

/**
 * Converte uma Order simulada para o payload necessário para salvar
 * o Pedido ao backend.
 */
export function toPedidoPayload(order: OrderSimulation): PedidoPayload {
  // Força pedidoReferencia: undefined para não dar
  // loop infinito de frontExtra quando houver simulação de aditivo
  const frontExtra = serialize({
    ...order,
    pedidoReferencia: undefined,
    orcamentoReferencia: undefined
  })
  const installmentToParcela = (
    installment: OrderSimulationInstallment
  ): ParcelaPedido => ({
    tipoProdutoParcela: installmentTypeToTipoParcela(installment.type),
    dataVencimento: moment(installment.expires).format('YYYY-MM-DD'),
    valor: installment.calculatedValue(order)
  })

  return {
    safraId: order.harvestId!,
    fazendaId: order.farmId!,
    vendedorId: order.professionalId!,
    usarCnpjFazenda: order.farmUseCnpj,
    tipoCategoriaCultivar: order.categoryCultive,
    frete: order.hasShipping
      ? {
          valorSacaNegociado: order.shippingCost
        }
      : undefined,
    pagamento: {
      prazoGermoplasma: moment(order.deadlines.seed).format('YYYY-MM-DD'),
      prazoTecnologia: moment(order.deadlines.royalties).format('YYYY-MM-DD'),
      prazoTratamento: moment(order.deadlines.treatment).format('YYYY-MM-DD'),
      parcelas: order.billing.installments.map(installmentToParcela)
    },
    itens: Object.values(order.items).map(toPedidoItems),
    observacao: order.observations,
    pedidoReferenciaId: order.pedidoReferencia && order.pedidoReferencia.id,
    solicitacaoOrcamentoId:
      order.orcamentoReferencia && order.orcamentoReferencia.id,
    frontExtra,
    tipoVenda: order.salesType,
    ValorSaca: order.bagValue,
    IsFreteCompradorFob: order.hasBuyerShipping,
    StatusLiberacaoTratamento: order.statusTSI
  }
}

export function toOrderSimulation(apiData: any): OrderSimulation {
  if (!apiData) return new OrderSimulation()

  const convertedOrder = plainToClass(
    OrderSimulation,
    {
      ...apiData,
      observations: apiData.notes,
      hasBuyerShipping: apiData.isFreteCompradorFob,
      hasShipping: apiData.shipping ? true : false,
      shippingCost: apiData.shipping ? apiData.shipping.valuePerSack : 0,
      statusTSI: apiData.statusLiberacaoTratamento,
      uploads: apiData.farm.cliente.uploads || [],
      items: apiData.items.map(transformOrderItem) || []
    },
    {
      enableImplicitConversion: false,
      excludeExtraneousValues: true
    }
  )

  convertedOrder.categoryCultive = apiData.categoryCultivar
    ? stringToTipoCategoriaCultivar(
        apiData.categoryCultivar as TipoCategoriaCultivar
      ) || 0
    : convertedOrder.categoryCultive

  if (apiData.payment) {
    convertedOrder.deadlines = Object.assign(
      convertedOrder.deadlines instanceof OrderSimulationDeadlines
        ? convertedOrder.deadlines
        : new OrderSimulationDeadlines(),
      transformDeadlines(apiData.payment)
    )

    if (apiData.payment.installments) {
      convertedOrder.billing = Object.assign(
        convertedOrder.billing instanceof OrderSimulationBilling
          ? convertedOrder.billing
          : new OrderSimulationBilling(),
        { installments: transformInstallments(apiData.payment.installments) }
      )
    }
  }

  if (apiData.farm) {
    Object.assign(convertedOrder, {
      farmRegionId: apiData.farm.cidade.regiaoId || null,
      clientId: apiData.farm.clienteId || null,
      client: apiData.farm.cliente || null
    })
  }

  return convertedOrder
}

function transformDeadlines(
  apiDeadlines: ApiDeadlines
): OrderSimulationDeadlines {
  const transformDate = (date: string) =>
    date
      ? moment(date, 'YYYY-MM-DD')
          .endOf('day')
          .toDate()
      : undefined

  return Object.assign(new OrderSimulationDeadlines(), {
    seed: transformDate(apiDeadlines.seedDeadline),
    royalties: transformDate(apiDeadlines.royaltiesDeadline),
    treatment: transformDate(apiDeadlines.treatmentDeadline)
  })
}

function transformInstallments(
  apiInstallments: ApiInstallment[]
): OrderSimulationInstallment[] {
  return apiInstallments.map(item =>
    Object.assign(new OrderSimulationInstallment(), {
      type: tipoParcelaToInstallmentType(item.type),
      expires: item.expiresAt ? moment(item.expiresAt).toDate() : null,
      value: item.value,
      unit: 'Manual'
    })
  )
}

function transformOrderItem(item: ApiItem) {
  return {
    ...item,
    quantityUnit: 'Sacks',
    quantity: item.totalSacks || 0,
    inputedArea: item.choosenArea || 0,
    deliveryObservations: item.observacoesEmbarque || '',
    populationRate: item.choosenPopulation || 0,
    recomendationRate: item.choosenRate || 0,
    roundMode: 'Up',
    embarkationDate: item.embarkationDate
      ? moment
          .utc(item.embarkationDate)
          .startOf('day')
          .toISOString()
      : null
  }
}

function toPedidoItems(item: OrderItem): ItemPedido {
  return {
    cultivarId: item.cultiveId!,
    valorGermoplasmaSacaNegociado: item.pricing.calculatedPrice,
    tratamentoId: item.treatmentId,
    quantidadeSaca: item.calculatedSacks,
    AreaEscolhida: item.inputedArea,
    DataEmbarque: item.embarkationDate,
    ObservacoesEntrega: item.deliveryObservations,
    PopulacaoEscolhida: item.populationRate,
    IRPercentualEscolhida: item.recomendationRate
  }
}

export function installmentTypeToTipoParcela(type: SimulationInstallmentType) {
  switch (type) {
    case SimulationInstallmentType.All:
      return 'Todos'
    case SimulationInstallmentType.Seed:
      return 'Semente'
    case SimulationInstallmentType.Royalties:
      return 'Tecnologia'
    case SimulationInstallmentType.Treatment:
      return 'Tratamento'
    default:
      return 'Nenhum'
  }
}

const stringToTipoCategoriaCultivar = (
  categoryCultivar: TipoCategoriaCultivar | undefined
) => {
  switch (categoryCultivar) {
    case TipoCategoriaCultivar.SafetyStand:
      return 30
    case TipoCategoriaCultivar.ReadySeed95:
      return 20
    case TipoCategoriaCultivar.DefaultSale:
      return 10
    default:
      return undefined
  }
}

export function tipoParcelaToInstallmentType(
  tipo: string
): SimulationInstallmentType {
  switch (tipo) {
    case 'Todos':
      return SimulationInstallmentType.All
    case 'Semente':
      return SimulationInstallmentType.Seed
    case 'Tecnologia':
      return SimulationInstallmentType.Royalties
    case 'Tratamento':
      return SimulationInstallmentType.Treatment
    default:
      return SimulationInstallmentType.All
  }
}
