import { Expose, Transform } from 'class-transformer'
import { min } from 'lodash'
import moment from 'moment'
import { toISO, toDate } from '../../shared/transformerHelpers'

/**
 * Prazos de pagamento do pedido
 */
export class OrderSimulationDeadlines {
  @Expose()
  @Transform(toISO, { toPlainOnly: true })
  @Transform(toDate, { toClassOnly: true })
  royalties?: Date

  @Expose()
  @Transform(toISO, { toPlainOnly: true })
  @Transform(toDate, { toClassOnly: true })
  seed?: Date

  @Expose()
  @Transform(toISO, { toPlainOnly: true })
  @Transform(toDate, { toClassOnly: true })
  treatment?: Date

  /**
   * Retorna o mês que começa o prazos de pagamentos
   */
  get earliest() {
    const dates = [this.royalties, this.seed, this.treatment]

    return min(dates)
  }

  get isAllDeadlinesSetup() {
    return (
      this.royalties !== undefined &&
      this.seed !== undefined &&
      this.treatment !== undefined
    )
  }

  get isAllEqual() {
    const seed = moment(this.seed)
    return (
      seed.isSame(this.royalties, 'month') &&
      seed.isSame(this.treatment, 'month')
    )
  }
}
