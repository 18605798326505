import { Type } from 'class-transformer'
import { OrderSimulation } from './OrderSimulation'
import {
  OrderSimulationInstallment,
  SimulationInstallmentType,
  SimulationInstallmentMode
} from './OrderSimulationInstallment'
import BigNumber from 'bignumber.js'
import { useNormalizedValue } from '../../hooks/useNormalizeValue'

/**
 * OrderSimulationBilling armazena as informações de pagamento/parcelamento.
 */

export class OrderSimulationBilling {
  @Type(() => OrderSimulationInstallment)
  installments = [new OrderSimulationInstallment()]

  get installmentAmount() {
    return this.installments.length
  }

  /**
   * Verifica se é parcela única
   */
  get isSingleInstallment() {
    return (
      this.installmentAmount === 1 &&
      this.installments[0].type === SimulationInstallmentType.All
    )
  }

  /**
   * Soma todas as parcelas especificas (germoplasma, tratamento e royalties).
   *
   * Ex:
   *  - Valor total da compra: 10000 (5000 total do germoplasma, 2000 de royalties)
   *
   *  - 1 parcela para germoplasma com 50% de porcentagem.
   *  - 1 parcela para royalties com 100% de porcentagem.
   *
   * O retorno seria de 4500 (2500 + 2000)
   */
  specificInstallmentsTotal(order: OrderSimulation) {
    const totalUsed = this.installments
      .filter(installment => installment.type !== SimulationInstallmentType.All)
      .reduce(
        (value, installment) => value + installment.calculatedValue(order),
        0
      )

    return useNormalizedValue(totalUsed)
  }

  /**
   * Retorna o saldo das parcelas não coberto por parcelas com tipo específico.
   *
   * Ex:
   *  - Valor total da compra: 10000 (5000 total do germoplasma, 2000 de royalties)
   *
   *  - 1 parcela para germoplasma com 50% de porcentagem.
   *  - 1 parcela para royalties com 100% de porcentagem.
   *
   * O retorno de useRemainingInstallmentsBalance seria de 5500 (10000 + 4500)
   *
   */
  remainingInstallmentsBalance(order: OrderSimulation) {
    const total = order.total - this.specificInstallmentsTotal(order)
    return useNormalizedValue(total)
  }

  /**
   * Retorna o valor restante para completar o valor total de um tipo de parcela.
   *
   * Desconsidera installments com completeRemaing === true
   */
  remaningSpecificBalance(
    type: SimulationInstallmentType,
    order: OrderSimulation
  ) {
    const remainingToType = this.installments
      .filter(
        installment => installment.type === type && !installment.completeRemaing
      )
      .reduce(
        (total, installment) => total + installment.calculatedValue(order),
        0
      )

    const total = this.getTotalByType(type, order) - remainingToType
    return useNormalizedValue(total)
  }

  /**
   * Retorna total a ser pago para um tipo parcela
   */
  getTotalByType(type: SimulationInstallmentType, order: OrderSimulation) {
    switch (type) {
      case SimulationInstallmentType.All:
        return order.billing.remainingInstallmentsBalance(order)
      case SimulationInstallmentType.Seed:
        return useNormalizedValue(order.seedTotal + order.shippingTotal)
      case SimulationInstallmentType.Treatment:
        return order.treatmentTotal
      case SimulationInstallmentType.Royalties:
        return order.royaltiesTotal
      default:
        return 0
    }
  }

  /**
   * Verifica se existe uma parcela que completa o valor restante
   * para um tipo de parcela.
   */
  isTypeCompletingRemaning(type: SimulationInstallmentType) {
    const itemIndex = this.installments.findIndex(
      installment => installment.type === type && installment.completeRemaing
    )
    return itemIndex !== -1
  }

  /**
   * Retorna o somatório de todas as parcelas.
   */
  installmentsTotal(order: OrderSimulation) {
    const filteredTotal = this.specificInstallmentsTotal(order)

    const totalFromAll = this.installments
      .filter(installment => installment.type === SimulationInstallmentType.All)
      .reduce(
        (value, installment) => value + installment.calculatedValue(order),
        0
      )

    const total = filteredTotal + totalFromAll
    return useNormalizedValue(total)
  }

  installmentsValueMatchOrderTotal(order: OrderSimulation) {
    const installmentsTotal = order.total - this.installmentsTotal(order)
    return useNormalizedValue(installmentsTotal) === 0
  }

  hasRoyaltiesInstallmentError(order: OrderSimulation) {
    const items = Object.values(order.items)
    const isNeededAInstallmentOfRoyalties = items.reduce(
      (isNeeded, item) => isNeeded || !!item.isWaitingForRoyalty,
      false
    )
    const hasARoyaltiesInstallment = this.installments.some(
      installment => installment.type === SimulationInstallmentType.Royalties
    )
    return isNeededAInstallmentOfRoyalties && !hasARoyaltiesInstallment
  }

  percentTotalPerType(order: OrderSimulation, type: SimulationInstallmentType) {
    return this.installments
      .filter(installment => installment.type === type)
      .reduce(
        (value, installment) => value + installment.calculatedPercentage(order),
        0
      )
  }

  hasRemainingCentsFromRound(order: OrderSimulation) {
    const isPercentFromAll100 = this.percentTotalPerType(
      order,
      SimulationInstallmentType.All
    )
    const isPercentFromRoyalties100 = this.percentTotalPerType(
      order,
      SimulationInstallmentType.Royalties
    )
    const isPercentFromTreatment100 = this.percentTotalPerType(
      order,
      SimulationInstallmentType.Treatment
    )
    const isPercentFromSeed100 = this.percentTotalPerType(
      order,
      SimulationInstallmentType.Seed
    )

    const hasAnyFullPercent =
      isPercentFromAll100 ||
      isPercentFromRoyalties100 ||
      isPercentFromTreatment100 ||
      isPercentFromSeed100

    const isRemainingCentsInThreshold =
      order.total - this.installmentsTotal(order) > 0 &&
      order.total - this.installmentsTotal(order) < 0.1

    return (
      !this.installmentsValueMatchOrderTotal(order) &&
      hasAnyFullPercent &&
      isRemainingCentsInThreshold
    )
  }

  distributeRemainingCents(order: OrderSimulation) {
    if (!this.hasRemainingCentsFromRound) return

    let diffCents = new BigNumber(
      order.total - this.installmentsTotal(order)
    ).decimalPlaces(2)

    this.installments.forEach(installment => {
      if (diffCents.toNumber() > 0) {
        installment.value = new BigNumber(installment.calculatedValue(order))
          .plus(0.01)
          .decimalPlaces(2)
          .toNumber()
        installment.unit = SimulationInstallmentMode.Manual
        diffCents = diffCents.minus(0.01)
      }
    })
  }

  hasBarterInstallmentBillingError(order: OrderSimulation) {
    const isBarter = order.salesType === 'Barter'

    if (!isBarter) return false

    const onlyOneBillingTypeAll =
      order.billing.installments.length === 2 &&
      order.billing.installments[0].type === 'All' &&
      order.billing.installments[1].type === 'All'
    const noBillingTypeAll =
      order.billing.installments.length === 1 &&
      order.billing.installments[0].type === 'Royalties'

    const isValid = onlyOneBillingTypeAll || noBillingTypeAll
    return isValid
  }

  hasErrors(order: OrderSimulation) {
    const installmentsHasError = this.installments.reduce(
      (hasError, installment) => {
        return hasError || installment.hasErrors(order)
      },
      false
    )

    return (
      installmentsHasError ||
      !this.installmentsValueMatchOrderTotal(order) ||
      this.hasRoyaltiesInstallmentError(order) ||
      this.hasBarterInstallmentBillingError(order)
    )
  }
}
